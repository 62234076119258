.Contact {
  display: flex;
  padding: 0 2rem;
  gap: 5rem;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  gap: 8rem;
}

.contact-l {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.contact-l > span:nth-child(1) {
  font-size: 3rem;
  font-weight: 700;
  color: var(--orange);
}

.contact-l > span:nth-child(2) {
  min-width: 30rem;
  color: var(--lightgray);
  font-size: 1rem;
  line-height: 2rem;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--lightgray);
  font-size: 1rem;
}

.contact-detail {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-r {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;
}

.contact-r > label {
  color: var(--lightgray);
  font-size: 1rem;
  font-weight: 500;
}

.contact-r > input {
  border: none;
  width: 43rem;
  height: 4rem;
  padding-left: 1rem;
  border-radius: 4px;
  background: #32323c;
  color: #a0a0a0;
  font-family: Outfit;
  font-size: 1rem;
}

.contact-r > textarea {
  width: 40rem;
  border: none;
  padding: 2rem;
  border-radius: 4px;
  background: #32323c;
  color: #a0a0a0;
  font-family: Outfit;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .Contact {
    margin: 0rem 1rem;
  }
  .content {
    flex-direction: column;
    gap: 2rem;
  }
  .contact-l > span:nth-child(1) {
    font-size: 2rem;
  }
  .contact-l > span:nth-child(2) {
    min-width: 100%;
  }
  .contact-details {
    gap: 1rem;
  }
  .contact-detail {
    gap: 0.5rem;
  }
  .contact-detail > img {
    width: 25px;
  }
  .contact-r {
    align-items: center;
    width: 100%;
  }
  .contact-r > input {
    width: 100%;
  }
  .contact-r > textarea {
    width: 80%;
  }
}
